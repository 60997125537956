html,
body {
    font-family: sans-serif;
    font-size: 13px;
    margin: 0;
    padding: 0;
}

.main {
    width: 900px;
    margin: 20px auto;
}

.mimeform {
    padding: 20px 0;
}

.html-content iframe {
    width: 1px;
    min-width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
}

.text-content {
    font-family: Sans-serif;
    white-space: pre-wrap;
    border: none;
    font-family: Sans-serif;
    font-size: 13px;
    overflow: scroll;
}

.email-info-row {
    display: flex;
}

.email-info-label {
    margin-right: 5px;
}

.attachments-container .content {
    display: flex;
}

.html-container,
.text-container,
.attachments-container,
.content-divider {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #aaa;
}

.container-label {
    font-weight: 900;
    padding-bottom: 5px;
}

.attachment-link {
    display: block;
    margin-right: 5px;
    padding: 5px;
    border: 1px solid #aaa;
    border-radius: 2px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.subject-content{
    font-weight: 900;
    font-size: 1.2rem;
}

.email-info-row {
    padding-top: 5px;
}

.email-loader {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.ant-upload-list-item-name {
    color: #0b33e6;
    cursor: pointer;
}